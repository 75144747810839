import { observer, Show, useObservable } from '@legendapp/state/react'
import { useForm, useField } from '@tanstack/react-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Checkbox, Modal, Switch, Tooltip } from 'antd'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Button, LoadingSpinner } from 'ui'
import { LogError, stateSelectOptions } from 'utils'

import { StatementSettings } from 'trellis:api/statements/statements-client'
import {
  GetPaymentsStatus,
  GetStatementSettings,
  UpdateStatementSettings,
} from 'trellis:api/statements/statementsApi'
import { NotifyText } from 'trellis:constants/notifyText'
import GlobalState, { LDFlags$ } from 'trellis:state/globalState'
import { showMessage } from 'trellis:utilities/general'

import { CVV_LEGAL_TEXT } from '../utils/billing-statements-helpers'
import styles from './BillingStatementSettings.module.scss'

import AmexCard from 'trellis:assets/amex-color.svg?react'
import DiscoverCard from 'trellis:assets/discover-color.svg?react'
import InfoIcon from 'trellis:assets/info.svg?react'
import MasterCard from 'trellis:assets/mastercard-color.svg?react'
import SettingsIcon from 'trellis:assets/settings.svg?react'
import VisaCard from 'trellis:assets/visa-color.svg?react'

type BillingStatementSettingsFormData = {
  CVV: boolean
  QR: boolean
  Discover: boolean
  MC: boolean
  Amex: boolean
  Visa: boolean
  RemitName: string
  RemitAddress1: string
  RemitAddress2: string
  RemitCity: string
  RemitState: string
  RemitZip: string
  AutoSendPaymentRequest: boolean
}

export const BillingStatementSettings = observer(() => {
  const showSettingsModal$ = useObservable<boolean>(false)
  const remitAddressRequired$ = useObservable<boolean>(false)

  const stateOptions$ = useObservable(stateSelectOptions)
  const flags = LDFlags$.get()

  useEffect(() => {
    const stateOptions = stateOptions$.peek()
    if (!stateOptions?.find((option) => option.value === ''))
      stateOptions.unshift({ text: '', label: '', value: '' })
    stateOptions$.set(stateOptions)
  }, [])

  const getStatementSettings = async () => {
    const { data } = await GetStatementSettings(GlobalState.Auth.peek())
    return data
  }

  const getPaymentsStatus = async () => {
    if (
      !GlobalState.PaymentsStatus.peek()?.Active &&
      !GlobalState.PaymentsStatus.peek()?.IsConfigured
    ) {
      const { data } = await GetPaymentsStatus()
      GlobalState.PaymentsStatus.set(data)
      return data
    }
  }

  const {
    data: settingsData,
    error,
    isError,
    isFetching,
  } = useQuery({
    queryKey: ['settingsData'],
    queryFn: getStatementSettings,
    staleTime: 3000,
    refetchOnWindowFocus: false,
  })

  const { mutate: updateSettings } = useMutation({
    mutationFn: (data: StatementSettings) =>
      UpdateStatementSettings(GlobalState.Auth.peek(), data),
    onSuccess: () => {
      showMessage('Statement settings successfully saved!', 'success');
    },
    onError: (error) => {
      showMessage(NotifyText.updateStatementSettingsError)
      LogError(error, 'Error saving settings for billing statements')
    },
  })

  const {
    data: paymentsStatus = GlobalState.PaymentsStatus.peek(),
    isFetching: isPaymentsStatusFetching,
  } = useQuery({
    queryKey: ['paymentsStatus'],
    queryFn: getPaymentsStatus,
  })

  const form = useForm<BillingStatementSettingsFormData>({
    defaultValues: {
      CVV: settingsData?.CVV ?? false,
      QR: settingsData?.QR ?? true,
      Discover: settingsData?.Discover ?? false,
      MC: settingsData?.MC ?? false,
      Amex: settingsData?.Amex ?? false,
      Visa: settingsData?.Visa ?? false,
      RemitName: settingsData?.RemitName ?? '',
      RemitAddress1: settingsData?.RemitAddress1 ?? '',
      RemitAddress2: settingsData?.RemitAddress2 ?? '',
      RemitCity: settingsData?.RemitCity ?? '',
      RemitState: settingsData?.RemitState ?? '',
      RemitZip: settingsData?.RemitZip ?? '',
      AutoSendPaymentRequest: settingsData?.AutoSendPaymentRequest ?? true,
    },
    onSubmit: async ({ value }) => {
      updateSettings(value)

      showSettingsModal$.set(false)
    },
  })

  const canSubmit = form.useStore((state) => state.canSubmit)

  if (isError) {
    showMessage(NotifyText.getStatementSettingsError)
    LogError(error, 'Error retreiving settings for billing statements')
    showSettingsModal$.set(false)
  }

  const checkLinkedFormValues = (formValues: StatementSettings) => {
    if (
      formValues.RemitName ||
      formValues.RemitAddress1 ||
      formValues.RemitCity ||
      formValues.RemitState ||
      formValues.RemitZip
    )
      return true
    else return false
  }

  const handleAddressChange = (
    fieldApi,
    value = null,
    maxLength: number = 0,
  ) => {
    const formValues = fieldApi.form.state.values as StatementSettings
    if (
      formValues.RemitName ||
      formValues.RemitAddress1 ||
      formValues.RemitCity ||
      formValues.RemitState ||
      formValues.RemitZip
    )
      remitAddressRequired$.set(true)

    if (value?.length > maxLength)
      return `Max length is ${maxLength} characters`
    else return undefined
  }

  const handleSubmitForInput = (fieldApi, value = null) => {
    const remitAddressRequired = remitAddressRequired$.get()
    const fieldsAreRequired = checkLinkedFormValues(fieldApi.form.state.values)
    if (!value && fieldsAreRequired && remitAddressRequired) {
      return `${mapFieldNameToLabel(fieldApi.name)} is required`
    } else return undefined
  }

  const mapFieldNameToLabel = (name: string) => {
    switch (name) {
      case 'RemitName':
        return 'Name'
      case 'RemitAddress1':
        return 'Address 1'
      case 'RemitAddress2':
        return 'Address 2'
      case 'RemitCity':
        return 'City'
      case 'RemitState':
        return 'State'
      case 'RemitZip':
        return 'Zip'
      default:
        return name
    }
  }

  const handleFormClose = () => {
    form.reset()
    showSettingsModal$.set(false)
  }

  const qrField = useField({
    form,
    name: 'QR',
  });

  return (
    <>
      <Modal
        data-testid='statement-settings-modal'
        title='Statement Settings'
        onCancel={handleFormClose}
        open={showSettingsModal$.get()}
        width={580}
        bodyStyle={{
          overflowY: 'visible',
          backgroundColor: 'var(--grey-100, #fafcff)',
        }}
        footer={[
          <Button
            key='Cancel'
            label='Cancel'
            onClick={handleFormClose}
          />,
          <Button
            key='Submit'
            disabled={!canSubmit}
            type='primary'
            htmlType='submit'
            label='Save'
            className={!canSubmit && 'ml-050'}
            onClick={() => form.handleSubmit()}
          />,
        ]}
      >
        {isFetching ? (
          <LoadingSpinner />
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault()
              e.stopPropagation()
              form.handleSubmit()
            }}
          >
            <p className={styles['statement-settings--info']}>
              Changes made to Statement Settings must be made prior to
              submitting statements. After saving, all updates will apply to
              future submitted statements.
            </p>
            <Show if={flags.billingStatementsQrcode}>
              {() => (
                <div className={styles['statement-settings--qr']}>
                  <div className={styles['statement-settings--qr-switch']}>
                    <form.Field name='QR'>
                      {({ state, handleChange }) => (
                        <Switch
                          className={styles[`${flags?.statementSettingsQrDefaultTrue && !(paymentsStatus?.Active && paymentsStatus?.IsConfigured) && 'statement-settings--qr-switch-hidden'}`]}
                          data-testid='statement-settings--qr-switch'
                          disabled={!paymentsStatus?.Active}
                          checked={state.value}
                          onChange={(checked) => {
                          handleChange(checked)
                          if (!checked) {
                            qrField.setValue(false);
                          }
                          }}
                        />
                      )}
                    </form.Field>
                    <p
                      className={
                        styles[
                          `${!paymentsStatus?.Active && 'statement-settings--qr-switch-disabled'}`
                        ]
                      }
                    >
                      Include online payment link on printed statement
                    </p>
                    <InfoIcon name='statement-settings-qr-code-info' />
                  </div>
                  {paymentsStatus?.Active && paymentsStatus?.IsConfigured ? (
                    <p className={styles['statement-settings--qr-content']}>
                      Include a QR code and payment link your patients can use
                      to make payments immediately online.
                    </p>
                  ) : (
                    <>
                      {isPaymentsStatusFetching ? (
                        <LoadingSpinner />
                      ) : paymentsStatus?.IsConfigured ? (
                        <div
                          className={styles['statement-settings--qr-marketing']}
                        >
                          <p
                            className={
                              styles[
                                'statement-settings--qr-marketing-info-title'
                              ]
                            }
                          >
                            Unlock Online Payments!
                          </p>
                          <p
                            className={
                              styles['statement-settings--qr-marketing-info']
                            }
                          >
                            To enable QR codes and pay-by-link options on your
                            printed statements, please install Vyne Sync on your
                            practice server. This will automate data from your
                            practice management system, streamlining payment
                            processing for your practice.
                          </p>
                          <Link
                            to='/Practice/Downloads'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <Button
                              className={
                                styles['statement-settings--qr-marketing-link']
                              }
                              type='primary'
                              label='Install Vyne Sync Now'
                            />
                          </Link>
                        </div>
                      ) : (
                        <div
                          className={styles['statement-settings--qr-marketing']}
                        >
                          <p
                            className={
                              styles['statement-settings--qr-marketing-info']
                            }
                          >
                            Get paid faster by including a payment link your
                            patients can use to make payments online!
                          </p>
                          <Link
                            to='https://vynedental.com/gettingstarted_payments_adding-online-payments-to-billing-statements/'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <Button
                              className={
                                styles['statement-settings--qr-marketing-link']
                              }
                              type='primary'
                              label='Learn More about Vyne Payments'
                            />
                          </Link>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </Show>
            { paymentsStatus?.Active && paymentsStatus?.IsConfigured && qrField.state.value ? (
            <div className={styles['statement-settings--auto-send-payment-request']}>
              <div className={styles['statement-settings--auto-send-payment-request-switch']}>
                <form.Field name='AutoSendPaymentRequest'>
                  {({ state, handleChange }) => (
                    <Switch
                      defaultChecked
                      checked={state.value}
                      onChange={(checked) => handleChange(checked)}
                    />
                  )}
                </form.Field>
                <p>Send text/email payment request with paper statement</p>
              </div>
            </div>
            )
             : null }
            <div className={styles['statement-settings--cvv']}>
              <div className={styles['statement-settings--cvv-switch']}>
                <form.Field name='CVV'>
                  {({ state, handleChange }) => (
                    <Switch
                      defaultChecked
                      checked={state.value}
                      onChange={(checked) => handleChange(checked)}
                    />
                  )}
                </form.Field>
                <p>Include entry field for CVV on printed statement</p>
              </div>
              <p
                className={`${styles['statement-settings--cvv-content']} ${styles['statement-settings--cvv-legal-info']}`}
              >
                {CVV_LEGAL_TEXT}
              </p>
            </div>
            <div className={styles['statement-settings--supported']}>
              <p className='fs-100'>Supported Cards</p>
              <div className={styles['statement-settings--supported-cards']}>
                <form.Field name='Discover'>
                  {({ state, handleChange }) => (
                    <Checkbox
                      checked={state.value}
                      onChange={(e) => handleChange(e.target.checked)}
                    >
                      <div
                        className={styles['statement-settings--supported-card']}
                      >
                        <p className='fs-0875'>Discover</p>
                        <DiscoverCard />
                      </div>
                    </Checkbox>
                  )}
                </form.Field>
                <form.Field name='MC'>
                  {({ state, handleChange }) => (
                    <Checkbox
                      checked={state.value}
                      onChange={(e) => handleChange(e.target.checked)}
                    >
                      <div
                        className={styles['statement-settings--supported-card']}
                      >
                        <p className='fs-0875'>Mastercard</p>
                        <MasterCard />
                      </div>
                    </Checkbox>
                  )}
                </form.Field>
                <form.Field name='Amex'>
                  {({ state, handleChange }) => (
                    <Checkbox
                      checked={state.value}
                      onChange={(e) => handleChange(e.target.checked)}
                    >
                      <div
                        className={styles['statement-settings--supported-card']}
                      >
                        <p className='fs-0875'>Amex</p>
                        <AmexCard />
                      </div>
                    </Checkbox>
                  )}
                </form.Field>
                <form.Field name='Visa'>
                  {({ state, handleChange }) => (
                    <Checkbox
                      checked={state.value}
                      onChange={(e) => handleChange(e.target.checked)}
                    >
                      <div
                        className={styles['statement-settings--supported-card']}
                      >
                        <p className='fs-0875'>Visa</p>
                        <VisaCard />
                      </div>
                    </Checkbox>
                  )}
                </form.Field>
              </div>
            </div>     
            <div className={styles['statement-settings--address']}>
              <div className={styles['statement-settings--address-info']}>
                <p>Remit Address Override</p>
                <Tooltip
                  placement='top'
                  title={
                    <p style={{ width: '200px' }}>
                      The remit address on your billing statements is the
                      address sent on your patient statements to Vyne. To
                      override this address, complete the following fields.{' '}
                    </p>
                  }
                >
                  <InfoIcon />
                </Tooltip>
              </div>
              <div className={styles['statement-settings--address-container']}>
                <form.Field
                  name='RemitName'
                  validators={{
                    onChangeListenTo: [
                      'RemitAddress1',
                      'RemitCity',
                      'RemitState',
                      'RemitZip',
                    ],
                    onChange: ({ value, fieldApi }) =>
                      handleAddressChange(fieldApi, value, 100),
                    onSubmit: ({ value, fieldApi }) =>
                      handleSubmitForInput(fieldApi, value),
                  }}
                >
                  {(field) => (
                    <div className='mb-100'>
                      <label
                        htmlFor={field.name}
                        className='fs-075'
                      >
                        Name
                      </label>
                      <input
                        id={field.name}
                        name={field.name}
                        value={field.state.value}
                        maxLength={100}
                        className={
                          styles['statement-settings--address-container__row']
                        }
                        onChange={(e) => field.handleChange(e.target.value)}
                      />
                      {field.state.meta.errors ? (
                        <p
                          role='alert'
                          className={styles['statement-settings__form-error']}
                        >
                          {field.state.meta.errors.join(', ')}
                        </p>
                      ) : null}
                    </div>
                  )}
                </form.Field>
                <form.Field
                  name='RemitAddress1'
                  validators={{
                    onChangeListenTo: [
                      'RemitName',
                      'RemitCity',
                      'RemitState',
                      'RemitZip',
                    ],
                    onChange: ({ value, fieldApi }) =>
                      handleAddressChange(fieldApi, value, 100),
                    onSubmit: ({ value, fieldApi }) =>
                      handleSubmitForInput(fieldApi, value),
                  }}
                >
                  {(field) => (
                    <div className='mb-100'>
                      <label
                        htmlFor={field.name}
                        className='fs-075'
                      >
                        Address 1
                      </label>
                      <input
                        id={field.name}
                        name={field.name}
                        value={field.state.value}
                        maxLength={100}
                        className={
                          styles['statement-settings--address-container__row']
                        }
                        onChange={(e) => field.handleChange(e.target.value)}
                      />
                      {field.state.meta.errors ? (
                        <p
                          role='alert'
                          className={styles['statement-settings__form-error']}
                        >
                          {field.state.meta.errors.join(', ')}
                        </p>
                      ) : null}
                    </div>
                  )}
                </form.Field>
                <form.Field
                  name='RemitAddress2'
                  validators={{
                    onChange: ({ value }) => {
                      if (value.length > 100)
                        return 'Max length is 100 characters'
                    },
                  }}
                >
                  {(field) => (
                    <div className='mb-100'>
                      <label
                        htmlFor={field.name}
                        className='fs-075'
                      >
                        Address 2
                      </label>
                      <input
                        id={field.name}
                        name={field.name}
                        value={field.state.value}
                        maxLength={100}
                        className={
                          styles['statement-settings--address-container__row']
                        }
                        onChange={(e) => field.handleChange(e.target.value)}
                      />
                    </div>
                  )}
                </form.Field>
                <div
                  className={
                    styles['statement-settings--address-container__row']
                  }
                >
                  <form.Field
                    name='RemitCity'
                    validators={{
                      onChangeListenTo: [
                        'RemitName',
                        'RemitAddress1',
                        'RemitState',
                        'RemitZip',
                      ],
                      onChange: ({ value, fieldApi }) =>
                        handleAddressChange(fieldApi, value, 50),
                      onSubmit: ({ value, fieldApi }) =>
                        handleSubmitForInput(fieldApi, value),
                    }}
                  >
                    {(field) => (
                      <div
                        className={
                          styles[
                            'statement-settings--address-container__row-input'
                          ]
                        }
                      >
                        <label
                          htmlFor={field.name}
                          className='fs-075'
                        >
                          City
                        </label>
                        <input
                          id={field.name}
                          name={field.name}
                          value={field.state.value}
                          maxLength={50}
                          style={{ width: '100%' }}
                          onChange={(e) => field.handleChange(e.target.value)}
                        />
                        {field.state.meta.errors ? (
                          <p
                            role='alert'
                            className={styles['statement-settings__form-error']}
                          >
                            {field.state.meta.errors.join(', ')}
                          </p>
                        ) : null}
                      </div>
                    )}
                  </form.Field>
                  <form.Field
                    name='RemitState'
                    validators={{
                      onChangeListenTo: [
                        'RemitName',
                        'RemitAddress1',
                        'RemitCity',
                        'RemitZip',
                      ],
                      onChange: ({ fieldApi }) => handleAddressChange(fieldApi),
                      onSubmit: ({ value, fieldApi }) =>
                        handleSubmitForInput(fieldApi, value),
                    }}
                  >
                    {(field) => (
                      <div
                        className={
                          styles[
                            'statement-settings--address-container__row-input'
                          ]
                        }
                      >
                        <label
                          htmlFor={field.name}
                          className='fs-075'
                        >
                          State
                        </label>
                        <select
                          name={field.name}
                          id={field.name}
                          style={{ width: '100%', height: '1.65em' }}
                          onChange={(e) => field.handleChange(e.target.value)}
                          value={field.state.value}
                        >
                          {stateOptions$.get().map((option) => {
                            return (
                              <option value={option.value}>
                                {option.text}
                              </option>
                            )
                          })}
                        </select>
                        {field.state.meta.errors ? (
                          <p
                            role='alert'
                            className={styles['statement-settings__form-error']}
                          >
                            {field.state.meta.errors.join(', ')}
                          </p>
                        ) : null}
                      </div>
                    )}
                  </form.Field>
                  <form.Field
                    name='RemitZip'
                    validators={{
                      onChangeListenTo: [
                        'RemitName',
                        'RemitAddress1',
                        'RemitCity',
                        'RemitState',
                      ],
                      onChange: ({ value, fieldApi }) =>
                        handleAddressChange(fieldApi, value, 15),
                      onSubmit: ({ value, fieldApi }) =>
                        handleSubmitForInput(fieldApi, value),
                    }}
                  >
                    {(field) => (
                      <div
                        className={
                          styles[
                            'statement-settings--address-container__row-input'
                          ]
                        }
                      >
                        <label
                          htmlFor={field.name}
                          className='fs-075'
                        >
                          Zip
                        </label>
                        <input
                          id={field.name}
                          name={field.name}
                          value={field.state.value}
                          maxLength={15}
                          style={{ width: '100%' }}
                          onChange={(e) => field.handleChange(e.target.value)}
                        />
                        {field.state.meta.errors ? (
                          <p
                            role='alert'
                            className={styles['statement-settings__form-error']}
                          >
                            {field.state.meta.errors.join(', ')}
                          </p>
                        ) : null}
                      </div>
                    )}
                  </form.Field>
                </div>
              </div>
            </div>
          </form>
        )}
      </Modal>
      <div>
        <Button
          data-testid='statement-settings-button'
          type='text'
          icon={<SettingsIcon />}
          onClick={() => showSettingsModal$.set(true)}
        />
      </div>
    </>
  )
})
