import DOMPurify from 'dompurify'
import { useEffect, useRef } from 'react'

const HtmlChunk = ({ data }: any) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      const shadowRoot = containerRef.current.attachShadow({ mode: 'open' })
      const sanitizedHtml = DOMPurify.sanitize(data)
      shadowRoot.innerHTML = sanitizedHtml
    }
  }, [data])

  return <div ref={containerRef}></div>
}

export default HtmlChunk
