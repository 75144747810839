import { GetCustomerPaymentStatus } from 'trellis:api/authentication/authenticationApi'
import GlobalState from 'trellis:state/globalState'

import { getPathWithQueryString } from './apiHelpers'

export const getPaymentReturnPath = (
  paymentPath: string,
  submitPaymentUrl: string,
  logoutUrl: string,
): string => {
  const returnPathQueryData = {
    submitPaymentUrl: submitPaymentUrl,
    logoutUrl: logoutUrl,
  }

  const returnPath = getPathWithQueryString(paymentPath, returnPathQueryData)
  if (!returnPath) {
    throw new Error(
      `Failed to build return path for path: ${paymentPath} submitPaymentUrl: ${submitPaymentUrl} logoutUrl: ${logoutUrl}`,
    )
  }

  return getPathWithQueryString(paymentPath, returnPathQueryData)
}

export const confirmPaymentAndReloadCustomer = async (
  customerId: number,
): Promise<boolean> => {
  const response = await GetCustomerPaymentStatus(customerId)
  if (response.data.paymentInformationRequired) {
    return false
  }
  GlobalState.PaymentOnFile.set(true)
  return true
}
