import { CameraFilled, SettingOutlined } from '@ant-design/icons';
import { Download } from '../../utils/downloads-helpers';

// Custom icon component to layer the icons
export const ImageSyncIcon = () => (
  <div style={{ position: 'relative', width: '24px', height: '24px', display: 'inline-block' }}>
    <SettingOutlined style={{ 
      fontSize: '24px', 
      color: '#91C73D',
      position: 'absolute',
      left: 0,
      top: 0
    }} />
    <div style={{
      position: 'absolute',
      left: '75%',
      top: '75%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      borderRadius: '50%',
      width: '14px',
      height: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <CameraFilled style={{   
        fontSize: '16px', 
        color: '#91C73D',
      }} />
    </div>
  </div>
);

export const getImageSyncDownload = (): Download => ({
  title: 'Image Sync',
  versionNumber: '1.0',
  version: '1.0',
  notes: 'Image Sync connects to your imaging system to make your patient\'s images easily selected for attachment to your claim. Note: Image Sync is required to be installed on your imaging system server.',
  inAdditionalUtilities: true,
  url: '#',
  tagline: '',
  confirmationPromptTitle: 'Image Sync is required to be installed on your imaging system server.',
  confirmationPromptMessage: 'Image Sync is required to be installed on your imaging system server.',
  beta: false,
  installGuideUrl: '',
  mailToBody: '',
  mailToSubject: '',
  productName: 'Image Sync',
  releaseDate: '',
  systemReqsUrl: '',
  logoUrl: '/assets/images/image-sync-logo.png'
}); 