import { observer } from '@legendapp/state/react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { LoadingSpinner } from 'ui'
import { LogError } from 'utils'

import { ProductType } from 'trellis:api/authentication/authentication-client'
import { GetSsoUrl } from 'trellis:api/authentication/authenticationApi'
import { SSOPaymentOnFile } from 'trellis:constants/general'
import { logoutUser } from 'trellis:features/authentication/Login/utils/login-helpers'
import GlobalState from 'trellis:state/globalState'
import { showMessage } from 'trellis:utilities/general'
import {
  confirmPaymentAndReloadCustomer,
  getPaymentReturnPath,
} from 'trellis:utilities/paymentHelper'

const PaymentInfoExternal: FC = observer(() => {
  const [loading, setLoading] = useState<boolean>(false)
  const location = useLocation()
  const ldClient = useLDClient()
  const navigate = useNavigate()
  const origin: string = window.location.origin
  const redirectPath: string = location?.state?.redirectURL
  const userInfo = GlobalState.UserInfo.get()

  useEffect(() => {
    setLoading(true)

    const handlePayment = async () => {
      try {
        const paymentOnFile = await confirmPaymentAndReloadCustomer(
          userInfo.globalCustomerID,
        )
        if (paymentOnFile) {
          navigate(redirectPath || SSOPaymentOnFile.redirectRootPath)
        } else {
          const paymentReturnPath = getPaymentReturnPath(
            SSOPaymentOnFile.submitPaymentPath,
            origin + (redirectPath || SSOPaymentOnFile.redirectRootPath),
            origin + SSOPaymentOnFile.logoutPath,
          )

          const ssoUrlresponse = await GetSsoUrl({
            destinationProduct: ProductType.Sso,
            returnPath: paymentReturnPath,
          })

          window.location.replace(ssoUrlresponse.data.ssoUrl)
        }
      } catch (error) {
        showMessage('There was a problem getting Payment Info')
        LogError(error, 'Failed to get Payment Info')
        logoutUser(ldClient)
      } finally {
        setLoading(false)
      }
    }

    handlePayment()
  }, [])

  return loading ? <LoadingSpinner /> : ''
})

export default PaymentInfoExternal
