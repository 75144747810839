import './userManagement.scss'
import { useEffect, useState } from 'react'
import { showMessage } from 'trellis:utilities/general'
import { getUserManagementUrl } from 'trellis:utilities/ssoHelper'
import { LoadingSpinner } from 'ui'
import { LogError } from 'utils'

export const UserManagement = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [userManagementUrl, setUserManagementUrl] = useState<string>()

  useEffect(() => {
    setLoading(true)
    try{
      (async () => {
        const url = await getUserManagementUrl()
        setUserManagementUrl(url)
      })()
    }catch(error){
      LogError(error, 'Failed to obtain user managagement ssoUrl')
      showMessage('There was an error displaying user management. Please try again.')
    }finally{
      setLoading(false)
    }
  }, [])

  if(loading) return <LoadingSpinner />

  return (!userManagementUrl ? '' :
    <article className='user-management__container'>
      <iframe
        id='iframe_userManagement'
        src={userManagementUrl}
        title='User Management Microsite'
        allow='clipboard-read; clipboard-write'
        style={{ width: '100%', minHeight: '100%', border: 0 }}
      ></iframe>
    </article>
  )
}
