
import trellisConfiguration from 'trellis:utilities/config'
import GlobalState from 'trellis:state/globalState'
import { Configuration, PearlCredentials, PearlProvisionApiFactory, ProvisionRequest } from './pearl-provision-client'

const apiEndpoint: string =
    trellisConfiguration.trellis_apiUrl + 'services/pearl'

export const GetImagingSystems = async () => {
  return await PearlProvisionApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
    })
  ).pearlProvisionGetImagingSystems()
}

export const GetPearlInstaller = async (
  customerId: number,
) => {
  return await PearlProvisionApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
  })
  ).pearlProvisionInstaller(customerId)
}

export const SavePearlCredentials = async (
  customerId: number,
  credentials: PearlCredentials,
) => {
  return await PearlProvisionApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
  })
  ).pearlProvisionSaveCredentials(customerId, credentials)
}

export const ProvisionPearl = async (
  customerId: number,
  request: ProvisionRequest,
) => {
  return await PearlProvisionApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
  })
  ).pearlProvisionProvision(customerId, request)
}

export const GetPearlStatus = async (
  customerId: number,
) => {
  return await PearlProvisionApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
  })
  ).pearlProvisionPearlStatus(customerId)
}

export const ProvisionPearlWithSystem = async (
  customerId: number,
  imageSystemIdentifier: string
) => {
  return await PearlProvisionApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
    })
  ).pearlProvisionProvision(customerId, { imageSystemIdentifier })
}

export const PollPearlStatus = async (
  customerId: number
) => {
  const status = await GetPearlStatus(customerId)
  if (status?.data?.hasImageSync) {
    const installerInfo = await GetPearlInstaller(customerId)
    return {
      hasImageSync: status.data.hasImageSync,
      hasCredentials: status.data.hasCredentials,
      installer: installerInfo?.data
    }
  }
  return {
    hasImageSync: status?.data?.hasImageSync || false,
    hasCredentials: status?.data?.hasCredentials || false
  }
}
