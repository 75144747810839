import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'

import { CarrierDto } from 'trellis:api/carrier/carrier-client'

import { ContactDataType, SupportedService } from './_carrierInfoTypes'

const enDash = '–'

export const getTableData = (carrier: CarrierDto): ContactDataType[] => {
  return [
    {
      key: '1',
      details: 'Primary',
      method: carrier.PrimaryPhone ? <a href={`tel:${carrier.PrimaryPhone}`}>{carrier.PrimaryPhone}</a> : enDash,
      email: carrier.PrimaryEmail ? <a href={`mailto:${carrier.PrimaryEmail}`}>{carrier.PrimaryEmail}</a> : enDash,
    },
    {
      key: '2',
      details: 'Provider Relations',
      method: carrier.ProviderRelationsPhone ? (
          <a href={`tel:${carrier.ProviderRelationsPhone}`}>
            {carrier.ProviderRelationsPhone}
          </a>
        ) : enDash,
      email: carrier.ProviderRelationsEmail ? (
          <a href={`mailto:${carrier.ProviderRelationsEmail}`}>
            {carrier.ProviderRelationsEmail}
          </a>
        ) : enDash,
    },
    {
      key: '3',
      details: 'Claim Support',
      method: carrier.ClaimsSupportPhone ? (
          <a href={`tel:${carrier.ClaimsSupportPhone}`}>
            {carrier.ClaimsSupportPhone}
          </a>
        ) : enDash,
      email: carrier.ClaimSupportEmail ? (
          <a href={`mailto:${carrier.ClaimSupportEmail}`}>
            {carrier.ClaimSupportEmail}
          </a>
        ) : enDash,
    },
    {
      key: '4',
      details: 'Provider Relations Fax',
      method: carrier.Fax || enDash,
      email: carrier.ProviderRelationsEmail ? (
          <a href={`mailto:${carrier.ProviderRelationsEmail}`}>
            {carrier.ProviderRelationsEmail}
          </a>
        ) : enDash,
    },
    {
      key: '5',
      details: 'Public Website',
      method: carrier.PublicWebsiteUrl ? (
          <a
            href={carrier.PublicWebsiteUrl}
            rel='noreferrer'
            target='_blank'
          >
            {carrier.PublicWebsiteUrl}
          </a>
        ) : enDash,
      email: enDash,
    },
    {
      key: '6',
      details: 'Provider Website',
      method: carrier.ProviderPortalWebsite ? (
          <a
            href={carrier.ProviderPortalWebsite}
            rel='noreferrer'
            target='_blank'
          >
            {carrier.ProviderPortalWebsite}
          </a>
        ) : enDash,
      email: enDash,
    },
  ]
}

export const getColumns = (): ColumnsType<ContactDataType> => {
  return [
    {
      title: 'Contact Details',
      dataIndex: 'details',
      key: 'details',
      width: 230,
    },
    {
      title: 'Contact Method',
      dataIndex: 'method',
      key: 'method',
      width: 230,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 230,
    },
  ]
}

// Helper to determine icon type
export const getIcon = (service: string | boolean) => {
  if (service)
    return (
      <CheckCircleOutlined className='carrier-info__sidebar-item--success' />
    )
  else
    return (
      <CloseCircleOutlined className='carrier-info__sidebar-item--failure' />
    )
}

// Helper for supported services logic
export const handleSupportedServices = (
  carrier: CarrierDto,
  supportedServices: SupportedService[],
) => {
  if (carrier.ClaimProcessingVendor === 'RSS') {
    supportedServices.map((service) => {
      service.icon = (
        <CloseCircleOutlined />
      )
    })
    supportedServices.unshift({
      label: 'Print and Mail',
      icon: (
        <CheckCircleOutlined />
      ),
    })
  }
  return supportedServices
}
