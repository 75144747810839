import { Observable } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { message, Modal, Select } from 'antd'
import { FC, useEffect } from 'react'
import styles from '../../_downloads.module.scss'

import { Button } from 'ui'

import { ImageSystem } from 'trellis:api/pearl/pearl-provision-client'
import { GetAvailableImagingSystems } from 'trellis:api/pearl/pearlProvisionApiService'
import { InfoCircleFilled } from '@ant-design/icons'


interface ImageSystemSelectionModalProps {
  isOpen: Observable<boolean>
  onClose?: () => void
  onSelectImageSystem: (value: string) => void
  onContinue?: () => void
}

interface SelectOption {
  value: string
  label: string
}

export const ImageSystemSelectionModal: FC<ImageSystemSelectionModalProps> =
  observer(({ isOpen, onClose, onSelectImageSystem, onContinue }) => {

    const selectedSystem = useObservable<string | undefined>(undefined)
    const imagingSystems = useObservable<SelectOption[]>([])
    const isLoadingSystems = useObservable(false)

    useEffect(() => {
      selectedSystem.set(undefined)

      const fetchImagingSystems = async () => {
        if (!isOpen.get()) {
          return
        }
        isLoadingSystems.set(true)

        try {
          const systems = await GetAvailableImagingSystems()
          
          const selectOptions = systems.map((system: ImageSystem) => ({
            value: system.imageSystemIdentifier || '',
            label: system.imageSystemName || '',
          }))

          imagingSystems.set(selectOptions)
        } catch (error) {
          await message.error('Failed to load imaging systems')
        } finally {
          isLoadingSystems.set(false)
        }
      }

      void fetchImagingSystems()
    }, [isOpen.get()])

    const handleContinue = async () => {
      const selectedSystemId = selectedSystem.get()
      if (!selectedSystemId) {
        onClose?.()
        return
      }

      onSelectImageSystem(selectedSystemId)
      onContinue?.()
    }

    const handleClose = () => {
      selectedSystem.set(undefined)
      onClose?.()
    }

    return (
      <>
        <Modal
          title='Download Image Sync'
          open={isOpen.get()}
          onCancel={handleClose}
          footer={[
            <Button
              key='cancel'
              label='Cancel'
              onClick={handleClose}
            />,
            <Button
              key='continue'
              type='primary'
              label='Continue'
              loading={isLoadingSystems.get()}
              disabled={!selectedSystem.get() || isLoadingSystems.get()}
              onClick={() => void handleContinue()}
            />,
          ]}
        >
          <div className='flex-column gap-100'>
            <div>
              <label>Select your current Imaging System:</label>
              <Select
                placeholder='Select'
                style={{ width: '100%', marginTop: '8px' }}
                onChange={(value: string) => selectedSystem.set(value)}
                value={selectedSystem.get()}
                loading={isLoadingSystems.get()}
                options={imagingSystems.get()}
              />
            </div>

            <div className={styles['download-item__note-container']}>
              <InfoCircleFilled className={styles['download-item__info-icon']} style={{ color: '#91c73d' }} />
              <p>
                <strong>Note:</strong> Image Sync requires a compatible imaging system. Please refer to the list to view supported systems.
              </p>
            </div>
          </div>
        </Modal>
      </>
    )
  })