import { observer, useObservable } from '@legendapp/state/react'
import { FC, useEffect } from 'react'

import { LogError } from 'utils'

import { Loading } from 'trellis:components/_siteWide/loader/dataDisplay'
import { Errors } from 'trellis:constants/errors'
import styles from 'trellis:features/downloads/_downloads.module.scss'
import { DownloadItem } from 'trellis:features/downloads/DownloadItem'
import { Download } from 'trellis:features/downloads/utils/downloads-helpers'
import GlobalState from 'trellis:state/globalState'
import api from 'trellis:utilities/api'
import { showMessage } from 'trellis:utilities/general'
import { getImageSyncDownload } from 'trellis:features/downloads/pearl/components/ImageSyncAssets'
import { ImageSyncDownloadItem } from 'trellis:features/downloads/pearl/components/ImageSyncDownloadItem'
import { LDFlags$ } from 'trellis:state/globalState'
import { hasImageSyncFeature } from 'trellis:api/pearl/pearlProvisionApiService'

export const Downloads: FC = observer(() => {
  const additionalDownloads = useObservable<Download[] | null>(null)
  const hasDownloads = useObservable<boolean>(false)
  const loading = useObservable<boolean>(true)
  const mainDownloads = useObservable<Download[] | null>(null)
  const isPearlConfigured = useObservable<boolean>(false)

  useEffect(() => {
    const init = async () => {
      loading.set(true)
      try {
        const imageSyncSelfInstall = LDFlags$.imageSyncSelfInstall.get()
        
        if (imageSyncSelfInstall) {
          await checkPearlConfiguration()
        } else {
          isPearlConfigured.set(false)
        }
        
        await handleGetDownloads()
      } finally {
        loading.set(false)
      }
    }
    void init()
  }, [])

  const checkPearlConfiguration = async () => {
    try {
      const customerId = GlobalState.Auth.CustomerIdNumber.get()
      const isConfigured = await hasImageSyncFeature(customerId)
      isPearlConfigured.set(isConfigured)
    } catch (error) {
      isPearlConfigured.set(false)
    }
  }

  const handleGetDownloads = async () => {
    try {
      const { data } = await api.getDownloads()

      if (data?.downloads) {
        const baseAdditionalDownloads = data.downloads.filter(
          (item: Download) => item.inAdditionalUtilities
        )
        
        const imageSyncDownload = {
          ...getImageSyncDownload(),
        }
        
        const customerId = GlobalState.Auth.CustomerIdNumber.get()
        
        additionalDownloads.set(
          isPearlConfigured.get()
            ? [imageSyncDownload, ...baseAdditionalDownloads]
            : baseAdditionalDownloads
        )

        const mainDownloadItems = data.downloads.filter(
          (item: Download) => !item.inAdditionalUtilities
        )

        mainDownloads.set(mainDownloadItems)
        hasDownloads.set(true)
      }
    } catch (err) {
      LogError(err)
      showMessage(Errors.getDownloadsError)
    } finally {
      loading.set(false)
    }
  }

  return (
    <div className='page-content'>
      <section className={styles['downloads-page__header']}>
        <h1 className={styles['downloads-page__title']}>Downloads</h1>
      </section>
      <div className={styles['downloads-page__content-container']}>
        {loading.get() ? (
          <Loading />
        ) : !hasDownloads.get() ? (
          <p className={styles['downloads-page__no-downloads']}>
            No Downloads are currently available
          </p>
        ) : (
          <>
            {mainDownloads.peek().map((item: Download, index: number) => (
              <DownloadItem
                key={index}
                {...item}
              />
            ))}
            {additionalDownloads.peek().length > 0 && (
              <>
                <p className={styles['download-item__tagline']}>
                  Additional Utilities
                </p>
                <div className={styles['download-item--additional-container']}>
                  {additionalDownloads.peek().map((item: Download, index: number) => (
                    item.title === 'Image Sync' ? (
                      <ImageSyncDownloadItem
                        key={index}
                        download={item}
                        isAdditional={true}
                      />
                    ) : (
                      <DownloadItem
                        key={index}
                        {...item}
                      />
                    )
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
})
