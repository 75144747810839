import './billingAndSubscription.scss'

import { useEffect, useState } from 'react'

import { LoadingSpinner } from 'ui'
import { LogError } from 'utils'

import { showMessage } from 'trellis:utilities/general'
import { getBillingAndSubscriptionUrl } from 'trellis:utilities/ssoHelper'

export const BillingAndSubscription = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [billingAndSubscriptionUrl, setBillingAndSubscriptionUrl] =
    useState<string>()

  useEffect(() => {
    setLoading(true)
    try {
      ;(async () => {
        const url = await getBillingAndSubscriptionUrl()

        setBillingAndSubscriptionUrl(url)
      })()
    } catch (error) {
      LogError(error, 'Failed to obtain user managagement ssoUrl')
      showMessage(
        'There was an error displaying user management. Please try again.',
      )
    } finally {
      setLoading(false)
    }
  }, [])

  if (loading) return <LoadingSpinner />

  return !billingAndSubscriptionUrl ? (
    ''
  ) : (
    <article className='billing-and-subscription__container'>
      <iframe
        id='iframe_billingAndSubscription'
        src={billingAndSubscriptionUrl}
        title='Billing and Subscription Microsite'
        allow='clipboard-read; clipboard-write'
        style={{ width: '100%', minHeight: '100%', border: 0 }}
      ></iframe>
    </article>
  )
}
