import { ProductType } from 'trellis:api/authentication/authentication-client'
import { GetSsoUrl } from 'trellis:api/authentication/authenticationApi'

export const getUserManagementUrl = async (): Promise<string> => {
  const userManagementPath = `user-management`
  const response = await GetSsoUrl({
    destinationProduct: ProductType.Sso,
    returnPath: userManagementPath,
  })
  return response.data.ssoUrl
}

export const getBillingAndSubscriptionUrl = async (): Promise<string> => {
  const billingAndSubscriptionPath = `billing-subscription`
  const response = await GetSsoUrl({
    destinationProduct: ProductType.Sso,
    returnPath: billingAndSubscriptionPath,
  })
  return response.data.ssoUrl
}
