import {
  ImageSyncImage,
  ImageSyncMultiPatient,
} from 'trellis:components/attachmentsShared/imageSync/imageSync.interface'
import { handleImageOrientation } from 'trellis:components/attachmentsShared/shared/sharedFunctions'
import { IAuthenticatedApiModel } from 'trellis:utilities/api'
import { dataURLtoFile } from 'trellis:utilities/general'

import {
  PearlConfiguration,
  PearlPatientImage,
} from './pearl-client'
import {
  GetImageFromPearl,
  GetPearlSettings,
  IsPearlConfigured,
  SearchPatientImages,
  SearchPatients,
} from './pearlApi'

export const GetCustomerSettings = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
): Promise<PearlConfiguration> => {
  let customerSettings = null as PearlConfiguration
  try {
    const settingsResponse = await GetPearlSettings(authentication, customerId)
    if (settingsResponse?.data) {
      customerSettings = settingsResponse.data
    }
  } catch {
    /* empty */
  }
  return customerSettings
}

export const GetPearlPatients = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
  organizationId: string,
  officeId: string,
  patientFirstName: string,
  patientLastName: string,
  patientDateOfBirth: string,
): Promise<ImageSyncMultiPatient[]> => {
  const imageSyncPatientList = [] as ImageSyncMultiPatient[]

  const patientListResponse = await SearchPatients(
    authentication,
    customerId,
    organizationId,
    officeId,
    patientFirstName,
    patientLastName,
    patientDateOfBirth,
  )
  if (patientListResponse?.data?.length == 0) {
    return imageSyncPatientList
  }

  let patientKey = 0

  for (const pearlPatient of patientListResponse.data) {
    const patient = {} as ImageSyncMultiPatient

    patient.key = ++patientKey
    patient.patientPearlId = pearlPatient.pearlPatientId
    patient.patientFirstName = pearlPatient.patientFirstName
    patient.patientLastName = pearlPatient.patientLastName
    patient.patientDateOfBirth = pearlPatient.patientDateOfBirth
    patient.patientLastImageDate = pearlPatient.patientLastImageDate
    patient.patientLastVisitDate = pearlPatient.patientLastVisitDate

    imageSyncPatientList.push(patient)
  }

  return imageSyncPatientList
}

export const GetPearlPatientImages = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
  organizationId: string,
  officeId: string,
  pearlPatientId: string,
): Promise<ImageSyncImage[]> => {
  const imageSyncImageList = [] as ImageSyncImage[]

  const patientImagesListResponse = await SearchPatientImages(
    authentication,
    customerId,
    pearlPatientId,
    organizationId,
    officeId,
  )
  if (!patientImagesListResponse?.data) {
    return imageSyncImageList
  }

  const patientImages: PearlPatientImage[] =
    patientImagesListResponse.data.patientImages

  let imageSynceImageKey = 0
  for (const pearlPatientImage of patientImages) {
    const imageSyncImage = {} as ImageSyncImage

    imageSyncImage.key = ++imageSynceImageKey
    imageSyncImage.imageId = pearlPatientImage.imageId
    imageSyncImage.imageFilename = pearlPatientImage.imageFilename
    imageSyncImage.imageDateTaken = pearlPatientImage.imageDateTaken
    imageSyncImage.imageType = pearlPatientImage.imageType
    imageSyncImage.orientation = handleImageOrientation(
      pearlPatientImage.imageOrientation,
    )
    imageSyncImage.notes = pearlPatientImage.imageToothRangeDisplay

    // get image data
    const imageData = await GetImageFromPearl(pearlPatientImage.imageUrl)
    const imageThumbnailData = await GetImageFromPearl(
      pearlPatientImage.imageUrlThumbnail,
    )

    imageSyncImage.imageData = dataURLtoFile(
      'data:image/jpeg;base64,' + imageData,
      imageSyncImage.imageFilename + '.jpg',
    )
    imageSyncImage.imageThumbnail = URL.createObjectURL(
      dataURLtoFile(
        'data:image/jpeg;base64,' + imageThumbnailData,
        imageSyncImage.imageFilename + '.jpg',
      ),
    )

    imageSyncImageList.push(imageSyncImage)
  }

  return imageSyncImageList
}

export const CheckPearlConfiguration = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
): Promise<boolean> => {
  try {
    const response = await IsPearlConfigured(authentication, customerId)
    return response?.data || false
  } catch {
    return false
  }
}