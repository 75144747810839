import { Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { StatusDisplay } from 'ui'
import { ClaimModel } from 'trellis:api/claim/claim-client'

import {
  ClaimColumnFilterOptions,
  ColumnType,
} from 'trellis:components/_siteWide/table/SmartTableTypes'
import { getStatusOverride } from 'trellis:constants/claimStatusDescriptionData'
import { formatColumnDate } from 'trellis:utilities/general'

import { ClaimDetailsModelExtended } from '../context/claimDetailContext'
import {
  ClaimModelExtended,
  useClaimGridContext,
} from '../context/claimGridContext'
import { getPopoverText } from '../util/status-code-helpers'
import { ClaimDetailColumn, ClaimDetailFilter } from './claimDetailColumn'
import { ClaimRowActionsMenu } from './ClaimRowActionsMenu/ClaimRowActionsMenu'


import textFormattingStyles from 'trellis:styles/text-formatting.module.scss'
import { ClaimGridState } from '../claimTypes'

export const getStatusDescriptionTooltip = (value: string, row: any) => {
  let alteredValue = null
  if (value) {
    alteredValue = value
      .replace('NEA Attachment', 'NEA')
      .replace('please add', '')
      .replace(' for this claim', '')
      .replace(' for Claim.', '')
      .replace('Please verify information and try again.', '')
  }

  const descriptionOverrides: string[] = row.MappedClaimStatusCodes?.map(
    (code) => code.DescriptionOverride,
  )
  const description = (
    <div
      className={textFormattingStyles['truncate-text']}
      data-testid={`claim-${row.ClaimID}-status-description`}
    >
      {descriptionOverrides?.length
        ? descriptionOverrides.join(', ')
        : alteredValue}
    </div>
  )
  // NOTE: length check is to only show tooltip if description is truncated in the grid
  // 45 is hard coded because there isn't a great way to check whether text is truncated
  // this could be done if we do a custom component
  const tooltip =
    descriptionOverrides?.join().length > 45 ||
    alteredValue?.length > 45 ||
    row?.StatusDescriptionTooltip ? (
      <div data-testid={`claim-${row.ClaimID}-status-tooltip`}>
        {descriptionOverrides?.length ? (
          descriptionOverrides.map((description, index) => (
            <div
              className='mb-050'
              key={`${row.ClaimID}-status-code-${index}`}
            >
              {description}
            </div>
          ))
        ) : (
          <>
            {alteredValue?.length > 45 && (
              <div className='mb-100'>{alteredValue}</div>
            )}
          </>
        )}
        {row.StatusDescriptionTooltip && (
          <div>{row.StatusDescriptionTooltip}</div>
        )}
      </div>
    ) : null

  return (
    <Tooltip
      mouseEnterDelay={1}
      title={tooltip}
    >
      {description}
    </Tooltip>
  )
}

export const getClaimColumns = (
  state: ClaimGridState,
  options: ClaimColumnFilterOptions,
) => {
  const { setClaimId, setDetailsTab } = useClaimGridContext()

  // Retrieve column widths from local storage to correctly set column width
  const customColumnWidths: { [column: string]: number } = JSON.parse(
    localStorage.getItem('claim-column-widths'),
  )

  return state.VisibleColumns.map((column: string) => {
    const match: ColumnType = {
      dataIndex: column,
      width: (customColumnWidths && customColumnWidths[column]) || 150,
      minWidth: 100,
      maxWidth: 200,
    }

    switch (column) {
      case 'Actions':
        match.noFilter = true
        match.width = 40
        match.title = ' ' // If you leave this blank or null it shows 'Actions' for the title
        match.render = (_: string, record: ClaimModel, index: number) => (
          <ClaimRowActionsMenu
            index={index}
            currentClaim={record}
          />
        )
        match.onCell = () => ({
          // This is overriding the 'setDetail' prop passed to SmartTable for the claim grid
          onClick: (e: React.MouseEvent) => e.stopPropagation(),
        })
        break
      case 'Details':
        match.render = (_, row: ClaimDetailsModelExtended) =>
          ClaimDetailColumn(row, setClaimId, setDetailsTab)
        match.filter = ClaimDetailFilter()
        match.width =
          (customColumnWidths && customColumnWidths[column as string]) || 110
        match.minWidth = 110
        match.maxWidth = 120
        break
      case 'TransmitDate':
        match.noFilter = true
        match.title =
          !state.ActiveTab || state.ActiveTab === 'Unsent'
            ? 'Received Date'
            : 'Sent Date'
        match.render = (value) => formatColumnDate(value, true)
        match.minWidth = 175
        match.maxWidth = 200
        match.width =
          (customColumnWidths && customColumnWidths[column as string]) || 175
        break
      case 'SubscriberName':
        match.width =
          (customColumnWidths && customColumnWidths[column as string]) || 180
        match.minWidth = 180
        match.maxWidth = 250
        break
      case 'PatientName':
        match.width =
          (customColumnWidths && customColumnWidths[column as string]) || 160
        match.minWidth = 160
        match.maxWidth = 250
        break
      case 'ServiceDate':
      case 'LastUpdate':
        match.noFilter = true
        match.render = (value: string | number | Date) =>
          formatColumnDate(value)
        match.width =
          (customColumnWidths && customColumnWidths[column as string]) || 175
        match.minWidth = 175
        match.maxWidth = 200
        break
      case 'Status':
        match.type = 'select'
        match.noFilter = true
        match.render = (value: string, row: ClaimModelExtended) => {
          const tooltipText = row.MappedClaimStatusCodes ? (
            getPopoverText(row.MappedClaimStatusCodes)
          ) : !row.StatusTooltip ? null : (
            <p style={{ color: 'var(--character-primary, #333)' }}>
              {row.StatusTooltip}
            </p>
          )
          return (
            <StatusDisplay
              status={value}
              toolTip={tooltipText}
              type='grid'
              queued={row.Queued}
              getStatusOverride={getStatusOverride}
              whiteBackgroundOverride={true}
            />
          )
        }
        match.width =
          (customColumnWidths && customColumnWidths[column as string]) || 190
        match.minWidth = 100
        match.maxWidth = 190
        break
      case 'StatusDescription':
        match.render = getStatusDescriptionTooltip
        match.width =
          (customColumnWidths && customColumnWidths[column as string]) || 250
        match.minWidth = 190
        match.maxWidth = 350
        break
      case 'Carrier':
        match.type = 'select'
        match.options = options.Carrier
        match.width =
          (customColumnWidths && customColumnWidths[column as string]) || 175
        match.minWidth = 175
        match.maxWidth = 250
        break
      case 'Remarks':
        match.width =
          (customColumnWidths && customColumnWidths[column as string]) || 350
        match.minWidth = 350
        match.maxWidth = 400
        break
      case 'Tin':
        match.type = 'select'
        match.options = options.Tin
        break
      case 'Predetermination':
        match.noFilter = true
        match.type = 'select'
        match.options = options.Predetermination
        break
      case 'TreatmentAddress':
        match.noFilter = true
        match.type = 'select'
        match.options = options.TreatmentAddress
        break
      case 'TreatmentCity':
        match.noFilter = true
        match.type = 'select'
        match.options = options.TreatmentCity
        break
      case 'BillingAddress':
        match.type = 'select'
        match.options = options.BillingAddress
        break
      case 'BillingCity':
        match.noFilter = true
        match.type = 'select'
        match.options = options.BillingCity
        break
      case 'NpiGroup':
        match.noFilter = true
        match.type = 'select'
        match.options = options.NpiGroup
        break
      case 'Install':
        match.noFilter = true
        match.type = 'select'
        match.options = options.Install
        break
      case 'TreatingSignature':
        match.noFilter = true
        match.type = 'select'
        match.options = options.TreatingSignature
        break
      case 'CobFlag':
        match.noFilter = true
        match.type = 'select'
        match.options = options.CobFlag
        break
      case 'Username':
        match.type = 'select'
        match.options = options.Username
        break
      default:
        match.width = customColumnWidths ? customColumnWidths[column] : 150
        match.minWidth = 100
        match.maxWidth = 200
        break
    }
    return match
  }) as ColumnsType<ColumnType>
}
