import { Form } from 'antd'
import { FC, useEffect, useState } from 'react'

import { Loading } from '../../_siteWide/loader/dataDisplay'
import { ClaimActivityLog } from '../components'
import {
  ClaimDetailContextProvider,
  useClaimDetailContext,
} from '../context/claimDetailContext'
import { useClaimGridContext } from '../context/claimGridContext'
import { ClaimToolbar } from '../controls/toolbar/claimToolbar'
import AttachmentContent from './content/AttachmentContent'
import EobContent from './content/EobContent'
import EraContent from './content/EraContent'
import PatientContent from './content/PatientContent'
import ProceduresContent from './content/ProceduresContent'
import { ProviderContent } from './content/ProviderContent'
import DetailHeader from './detailHeader/detailHeader'
import { ClaimStatus } from './tabs/status/claimStatus'

import './claimDetail.scss'

import { setTag } from 'utils'

import ClaimReceiptContent from './content/ClaimReceiptContent'

const ClaimDetailComponent: FC = () => {
  const { claim, getClaim, getEmptyClaim, fullScreen, initialClaimState } =
    useClaimDetailContext()
  const [loading, setLoading] = useState(true)
  const { ClaimState$, detailsTab } = useClaimGridContext()
  const [distanceFromTop, setDistanceFromTop] = useState<number>(null)

  useEffect(() => {
    const loadClaimDetail = async () => {
      setTag('CreateAClaim', ClaimState$.isNewClaim.peek())
      if (ClaimState$.isNewClaim.peek()) await getEmptyClaim()
      else await getClaim()

      //this is needed to wait on attachments to finish loading inside of getClaim
      setLoading(false)
    }

    loadClaimDetail()

    const formDetailContainer: HTMLElement =
      document.querySelector('.tab-content')
    if (formDetailContainer)
      setDistanceFromTop(
        Math.round(formDetailContainer.getBoundingClientRect().top),
      )
  }, [])

  let content: JSX.Element
  const [form] = Form.useForm()
  form.setFieldsValue({
    ...claim,
    PatientRelationship: claim?.RELATIONSHIP || 'SELF',
  })

  switch (detailsTab) {
    case 'Status':
      content = <ClaimStatus />
      break
    case 'Patient':
      content = <PatientContent />
      break
    case 'Procedures':
      content = <ProceduresContent />
      break
    case 'Provider':
      content = <ProviderContent />
      break
    case 'Attachments':
      content = <AttachmentContent />
      break
    case 'Activity Log':
      content = (
        <ClaimActivityLog
          claim={claim}
          activityLog={claim?.ActivityLog}
        />
      )
      break
    case 'EOB':
      content = <EobContent data={claim?.EOB_HTML} />
      break
    case 'ERA':
      content = <EraContent data={claim?.ERA_HTML} />
      break
    case 'Claim Receipt':
      content = <ClaimReceiptContent data={claim?.CLAIM_RECEIPT_HTML} />
      break
  }

  return (
    <div className={`${fullScreen ? 'full-screen' : ''}`}>
      <Form
        className='claim-detail-form'
        form={form}
        initialValues={{
          ...claim,
          PatientRelationship: claim?.RELATIONSHIP || 'SELF',
        }}
      >
        {initialClaimState && (
          <>
            {!fullScreen && <DetailHeader />}
            <ClaimToolbar loading={loading} />
            <div
              className={`tab-content ${
                distanceFromTop ? `tab-content--${distanceFromTop}` : ''
              }`}
            >
              {!loading && (claim || ClaimState$.showClaimDetail.peek()) ? (
                content
              ) : (
                <Loading />
              )}
            </div>
          </>
        )}
      </Form>
    </div>
  )
}

export const ClaimDetail = () => {
  return (
    <ClaimDetailContextProvider>
      <ClaimDetailComponent />
    </ClaimDetailContextProvider>
  )
}
