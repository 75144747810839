import { Buffer } from 'buffer'

import axios from 'axios'

import trellisConfiguration from 'trellis:utilities/config'

import { IAuthenticatedApiModel } from '../../utilities/api'
import { Configuration, PearlApiFactory } from './pearl-client'


export const GetPearlSettings = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
) => {
  return await PearlApiFactory(
    new Configuration({
      basePath: trellisConfiguration.trellis_apiUrl + 'services/pearl',
      accessToken: authentication.AccessToken,
    }),
  ).pearlGetPearlCustomerSettings(customerId)
}

export const SearchPatients = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
  organizationId: string,
  officeId: string,
  patientFirstName: string,
  patientLastName: string,
  patientDateOfBirth: string,
) => {
  return await PearlApiFactory(
    new Configuration({
      basePath: trellisConfiguration.trellis_apiUrl + 'services/pearl',
      accessToken: authentication.AccessToken,
    }),
  ).pearlSearchPearlCustomerPatients(customerId, {
    organizationId,
    officeId,
    patientFirstName,
    patientLastName,
    patientDateOfBirth,
  })
}

export const SearchPatientImages = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
  pearlPatientId: string,
  organizationId: string,
  officeId: string,
) => {
  return await PearlApiFactory(
    new Configuration({
      basePath: trellisConfiguration.trellis_apiUrl + 'services/pearl',
      accessToken: authentication.AccessToken,
    }),
  ).pearlSearchPearlPatientImages(customerId, pearlPatientId, {
    organizationId,
    officeId,
  })
}

export const GetImageFromPearl = async function (imageUrl: string) {
  return await axios
    .get(imageUrl, {
      responseType: 'arraybuffer',
    })
    .then((response) => Buffer.from(response.data, 'binary').toString('base64'))
    .catch((ex) => {})
}

export const IsPearlConfigured = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
) => {
  return await PearlApiFactory(
    new Configuration({
      basePath: trellisConfiguration.trellis_apiUrl + 'services/pearl',
      accessToken: authentication.AccessToken,
    }),
  ).pearlIsPearlConfigured(customerId)
}

