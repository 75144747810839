import { PaperClipOutlined, SearchOutlined } from '@ant-design/icons'
import { Col, Row, Space, Tooltip } from 'antd'
import React from 'react'

import { DetailFilterNames } from '../../../constants/general'
import { useClaimGridContext } from '../context/claimGridContext'

import './claimDetailColumn.scss'

export const ClaimDetailFilter = () => {
  const { state, detailsFilterChange } = useClaimGridContext()

  const onDetailChange = (confirm: any, detail: string) => {
    confirm()
    detailsFilterChange(detail)
  }

  return {
    filterDropdown: ({ confirm }: any) => (
      <div className='detail-filter-container'>
        <Space>
          <PaperClipOutlined
            className='attachment-icon grid-control-icon'
            onClick={() =>
              onDetailChange(confirm, DetailFilterNames.hasAttachment)
            }
          />
          {state.ActiveTab === 'Sent' && (
            <>
              <div
                className='eob-icon'
                onClick={() =>
                  onDetailChange(confirm, DetailFilterNames.hasEob)
                }
              />
              <div
                className='era-icon'
                onClick={() =>
                  onDetailChange(confirm, DetailFilterNames.hasEra)
                }
              />
            </>
          )}
        </Space>
      </div>
    ),
    filterIcon: () => (
      <>
        <div className='color--column--title column-header-title'>Details</div>
        <SearchOutlined />
      </>
    ),
  }
}

export const ClaimDetailColumn = (
  row: any,
  setClaimId: any,
  setDetailsTab: any,
) => {
  const openClaimDetails = (tab: string) => {
    setClaimId(row.ClaimID)
    setDetailsTab(tab)
  }

  const attachmentIcon = row.HasAttachment && (
    <Tooltip title={row.AttachmentId}>
      <PaperClipOutlined
        className='attachment-icon column grid-control-icon'
        onClick={() => openClaimDetails(DetailFilterNames.attachments)}
      />
    </Tooltip>
  )

  const eraIcon = row.HasEra && (
    <Tooltip title={DetailFilterNames.era}>
      <div
        className='era-icon'
        onClick={() => openClaimDetails(DetailFilterNames.era)}
      />
    </Tooltip>
  )

  const eobIcon = row.HasEob  && (
    <Tooltip title={DetailFilterNames.eob}>
      <div
        className='eob-icon'
        onClick={() => openClaimDetails(DetailFilterNames.eob)}
      />
    </Tooltip>
  )

  return (
    <Row
      justify='center'
      align='middle'
      className='attachment-icons__row'
    >
      <Col span={6}>{attachmentIcon}</Col>
      <Col span={6}>{eobIcon}</Col>
      <Col span={6}>{eraIcon}</Col>
    </Row>
  )
}
