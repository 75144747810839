import { Observable } from '@legendapp/state'
import styles from '../../_downloads.module.scss'
import { observer, useObservable } from '@legendapp/state/react'
import { Modal } from 'antd'
import { FC, useEffect } from 'react'
import { Button } from 'ui'
import ContactSupport from '../../../../components/layouts/PostAuthLayout/components/Header/modals/ContactSupport'
import GlobalState from 'trellis:state/globalState'
import { GetPearlConfigurationStatus } from 'trellis:api/pearl/pearlProvisionApiService'
import { PearlConfigurationStatus } from 'trellis:api/pearl/pearl-provision-client'

type ErrorType = 'DUPLICATE_CUSTOMER' | 'GENERAL_ERROR' | 'UNSUPPORTED_SYSTEM' | undefined;

interface ImageSyncErrorModalProps {
  isOpen: Observable<boolean>
  onClose: () => void
  openCredentialsForm: () => void
  errorType: ErrorType
  errorMessage?: string
}

export const ImageSyncErrorModal: FC<ImageSyncErrorModalProps> = observer(
  ({ isOpen, onClose, openCredentialsForm, errorType = 'GENERAL_ERROR', errorMessage }) => {
    const isContactSupportOpen = useObservable(false)
    const pearlStatus = useObservable<PearlConfigurationStatus | null>(null)

    useEffect(() => {
      if (isOpen.get()) {
        void checkPearlStatus()
      }
    }, [isOpen.get()])

    const checkPearlStatus = async () => {
      const customerId = GlobalState.Auth.CustomerIdNumber.get()

      const response = await GetPearlConfigurationStatus(customerId)
      if (response?.data) {
        pearlStatus.set({
          hasImageSync: response.data.hasImageSync ?? false,
          hasCredentials: response.data.hasCredentials ?? false
        })
      }
    }

    const openContactSupport = (e: React.MouseEvent) => {
      e.preventDefault()
      isContactSupportOpen.set(true)
    }

    const SupportMessage = () => (
      <p>
        Please <a href="#" onClick={openContactSupport}>
          contact our Support Team
        </a> to assist with your installation.
      </p>
    )

    const getErrorMessage = () => {
      switch (errorType) {
        case 'DUPLICATE_CUSTOMER':
          return 'This account appears to be already registered with Pearl. Our support team can help you recover access to your existing Pearl account.'
        case 'UNSUPPORTED_SYSTEM':
          return 'Your imaging system is not currently supported. Please contact support to discuss alternative solutions.'
        default:
          return errorMessage || 'An error occurred during the download process. Please contact support for assistance.'
      }
    }

    return (
      <>
        <Modal
          title="Unable to Download"
          open={isOpen.get()}
          onCancel={onClose}
          footer={[
            <div key="footer-content" className="flex-row items-center justify-between w-full">
              {!pearlStatus.get()?.hasCredentials && (
                <a 
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    openCredentialsForm()
                  }}
                  style={{ color: '#868686', fontSize: '14px' }}
                >
                  SUPPORT USE ONLY
                </a>
              )}
              <Button
                key="close"
                label="Close"
                onClick={onClose}
              />
            </div>
          ]}
        >
          <div className="flex-column gap-100">
            <h6 className={styles['download-item__title']}>{getErrorMessage()}</h6>
            <SupportMessage />
          </div>
        </Modal>

        <ContactSupport 
          isVisible={isContactSupportOpen.get()}
          setIsVisible={(value) => isContactSupportOpen.set(value)}
        />
      </>
    )
  }
) 