import { Observable, ObservableObject } from '@legendapp/state'

import { QueuedStatusSummary, SummaryCardsContainerItem } from 'ui'

import CalendarIcon from 'trellis:assets/calendar_blue.svg?react'
import ErrorIcon from 'trellis:assets/error_red.svg?react'
import WarningIcon from 'trellis:assets/notifications_orange.svg?react'
import CheckIcon from 'trellis:assets/check_circle_green.svg?react'

export type AppointmentSummaryCardItems = {
  appointments?: number
  needsAttention?: number
  eligible?: number
  pendingPatientResponse?: number
}

export const handleActiveSummaryCard = (
  state: any,
  activeSummaryCard: Observable<string | number>,
) => {
  switch (state.Filters.Config.Status) {
    case '':
    case null:
    case undefined:
      activeSummaryCard.set('default')
      break
    case 'Needs Attention':
      activeSummaryCard.set('needs attention')
      break
    case 'Pending Patient Response':
      activeSummaryCard.set('pending')
      break
    case 'Eligible':
      activeSummaryCard.set('eligible')
      break
    default:
      activeSummaryCard.set('')
      break
  }
}

export const handleSummaryFilter = (
  filter: string | number,
  activeSummaryCard: Observable<string | number>,
  state$: Observable<any> //ObservableObject<ClaimGridState>,
) => {
  const copy = { ...state$.peek() }
  copy.ActiveTab = 'Unsent'
  copy.Key = ++copy.Key
  copy.Filters.CurrentPage = 1

  if (filter === 'reset' || activeSummaryCard.peek() === filter) {
    delete copy.Filters.Config['Status']
  } else if (filter === 'needs attention') {
    copy.Filters.Config['Status'] = 'Needs Attention'
  } else if (filter === 'pending') {
    copy.Filters.Config['Status'] = 'Pending Patient Response'
  } else if (filter === 'eligible') {
    copy.Filters.Config['Status'] = 'Eligible'
  }

  state$.set(copy)
}

export const getAppointmentSummaryCardItems = (
  queuedStatusSummary: QueuedStatusSummary,
  activeSummaryCard: Observable<string | number>,
  state$: Observable<any>, //ObservableObject<ClaimGridState>,
): Promise<SummaryCardsContainerItem[]> => {
  const summary: AppointmentSummaryCardItems = {
    needsAttention: 0,
    appointments: 0,
    eligible: 0,
    pendingPatientResponse: 0,
  }

  queuedStatusSummary?.summary?.forEach((item) => {
    switch (item.Status) {
      case 'Appointments':
        summary.appointments = item.Count
        break
      case 'NeedsAttention':
        summary.needsAttention = summary.needsAttention + item.Count
        break
      case 'PendingPatientResponse':
        summary.pendingPatientResponse = item.Count
        break
      case 'Eligible':
        summary.eligible = item.Count
        break
    }
  })

  const summaryCardsData: SummaryCardsContainerItem[] = [
    {
      action: () => handleSummaryFilter('reset', activeSummaryCard, state$),
      color: 'blue',
      icon: <CalendarIcon />,
      id: 'default',
      title: 'Appointments',
      total: summary?.appointments,
      testId: 'appointment-default',
    },
    {
      action: () => handleSummaryFilter('needs attention', activeSummaryCard, state$),
      color: 'red',
      icon: <ErrorIcon />,
      id: 'attention',
      title: 'Needs Attention',
      total: summary?.needsAttention,
      testId: 'appointment-attention',
    },
    {
      action: () => handleSummaryFilter('pending', activeSummaryCard, state$),
      color: 'orange',
      icon: <WarningIcon />,
      id: 'pending',
      title: 'Pending Response',
      total: summary?.pendingPatientResponse,
      testId: 'appointment-pending',
    },
    {
      action: () => handleSummaryFilter('eligible', activeSummaryCard, state$),
      color: 'green',
      icon: <CheckIcon />,
      id: 'eligible',
      title: 'Eligible',
      total: summary?.eligible,
      testId: 'appointment-eligible',
    },
  ]

  return Promise.resolve(summaryCardsData)
}
