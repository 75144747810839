import { PrinterOutlined } from '@ant-design/icons'
import { Button, Popover, Tooltip } from 'antd'
import React, { FC, useState } from 'react'

import { AttachmentPrint } from '../../../attachment/attachmentDetails/attachmentPrint'
import { useClaimDetailContext } from '../../context/claimDetailContext'

export const PrintIcon: FC = () => {
  const [showingPrintPopover, setShowingPrintPopover] = useState(false)
  const {
    claim,
    printClaim,
    printHtml,
    printingClaim,
    printingEob,
    printingEra,
    printingClaimReceipt,
    attachment,
  } = useClaimDetailContext()

  const eob = claim?.EOB_HTML
  const era = claim?.ERA_HTML
  const claimReceipt = claim?.CLAIM_RECEIPT_HTML

  const showOtherPrintOptions: boolean =
    !!eob || !!era || attachment?.isSentAttachment === true

  const printContent: JSX.Element = (
    <div className='print-icon-popover'>
      <Button
        type='primary'
        loading={printingClaim}
        onClick={() => printClaim()}
      >
        Claim
      </Button>
      {eob && (
        <Button
          loading={printingEob}
          onClick={() => printHtml(eob)}
        >
          EOB
        </Button>
      )}
      {era && (
        <Button
          loading={printingEra}
          onClick={() => printHtml(era)}
        >
          ERA
        </Button>
      )}
      {claimReceipt && (
        <Button
          loading={printingClaimReceipt}
          onClick={() => printHtml(claimReceipt)}
        >
          Claim Receipt
        </Button>
      )}
      {attachment?.isSentAttachment === true && (
        <Button onClick={() => AttachmentPrint(attachment, claim)}>
          Attachment
        </Button>
      )}
    </div>
  )

  return (
    <>
      {showOtherPrintOptions ? (
        <Popover
          content={printContent}
          title='Print'
          trigger='click'
          placement='bottom'
          open={showingPrintPopover}
          onOpenChange={(isVisible: boolean) =>
            setShowingPrintPopover(isVisible)
          }
        >
          <Tooltip
            title='Print'
            mouseEnterDelay={0.5}
          >
            <PrinterOutlined
              onClick={() => setShowingPrintPopover(true)}
              className='grid-control-icon'
            />
          </Tooltip>
        </Popover>
      ) : (
        <Tooltip
          title='Print'
          mouseEnterDelay={0.5}
        >
          <PrinterOutlined
            onClick={() => printClaim()}
            className='grid-control-icon'
          />
        </Tooltip>
      )}
    </>
  )
}

export default PrintIcon
