import { Observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { Modal } from 'antd'
import { FC } from 'react'
import { Button } from 'ui'
import { CheckCircleOutlined } from '@ant-design/icons'
import styles from '../../_downloads.module.scss'

interface ImageSyncSetupCompleteModalProps {
  isOpen: Observable<boolean>
  onClose?: () => void
}

export const ImageSyncSetupCompleteModal: FC<ImageSyncSetupCompleteModalProps> = observer(
  ({ isOpen, onClose }) => {
    return (
      <Modal
        title="Provisioning Complete"
        open={isOpen.get()}
        onCancel={onClose}
        footer={[
          <Button
            key="close"
            label="Close"
            onClick={onClose}
          />
        ]}
      >
        <div className="flex-column items-center gap-100">
          <CheckCircleOutlined style={{ fontSize: '48px', color: '#52c41a' }} />
          <h6 className={styles['download-item__title']}>Pearl Verification Successful</h6>
          <p>We successfully verified your Pearl credentials!</p>
        </div>
      </Modal>
    )
  }
) 