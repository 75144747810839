import { Col, Row, Table } from 'antd'
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ResizeCallbackData } from 'react-resizable'

import { hasProperties, spacesToProperty } from '../../../utilities/general'
import { DateRangeState } from '../../Eligibility/shared/context/EligibilityContext'
import FilterTags from './FilterTags'
import ResizableColumnHeader from './ResizableColumnHeader'
import {
  ColumnType,
  DirectionType,
  EntryDisplayType,
  ObjAnyPropsType,
  PaginationType,
  StatusCSSClassType,
  StatusType,
} from './SmartTableTypes'
import SortHeader from './sortHeader/SortHeader'

import './SmartTable.scss'

import { ColumnsType } from 'antd/lib/table'

import BatchActionGridControls from 'trellis:components/BatchActionGridControls/BatchActionGridControls'

const store: Storage = window.localStorage

type SmartTableProps = {
  className?: string
  columns: ObjAnyPropsType[]
  datePicker?: JSX.Element
  dateRange?: DateRangeState
  defaultSearch?: { [key: string]: string }
  filterLoading?: boolean
  getData: Function
  isFullViewport?: boolean
  loading: boolean
  noFilter?: boolean
  rowKey: string | ((row: any) => void)
  selections?: { key: string; onSelect: Function }[]
  setDateRange?: Dispatch<SetStateAction<DateRangeState>>
  setDetail?: Function
  setState: Dispatch<SetStateAction<any | ObjAnyPropsType>>
  state: any
  storageKey?: string
  tableClass: string
  toolbar?: JSX.Element
  allowColumnResize?: boolean
  customColumnStorage?: string
  customColumnWidths?: { [column: string]: number }
  showBatchActionControls?: boolean
  hideFilterTags?: boolean
}

export interface SmartTableState<RecordType> {
  Key: number
  Data: RecordType[]
  Total: number
  Filters: SmartTableFilters
  SelectAll?: boolean
  SelectedIds?: string[]
  SelectedCount?: number
}
export interface SmartTableFilters {
  CurrentPage: number
  PageSize: number
  Config?: Map<string, any>
}

export const SmartTable: FC<SmartTableProps> = ({
  columns,
  datePicker,
  dateRange,
  defaultSearch,
  filterLoading,
  getData,
  isFullViewport,
  loading,
  noFilter,
  rowKey,
  selections,
  setDetail,
  setState,
  state,
  tableClass,
  setDateRange,
  toolbar,
  allowColumnResize,
  customColumnStorage,
  customColumnWidths,
  showBatchActionControls,
  hideFilterTags,
}: any) => {
  const [smartTableColumns, setSmartTableColumns] = useState(columns)

  const [openDate, setOpenDate] = useState<boolean>(false)
  const [search, setSearch] = useState<ObjAnyPropsType>(defaultSearch)
  const [tableScrollHeight, setTableScrollHeight] = useState<number>(847)

  const searchInput = useRef<HTMLElement>(null)

  const gridFilters: ObjAnyPropsType = state.Filters
  const properties: boolean = hasProperties(gridFilters.Config)
  const entries: [string, EntryDisplayType][] = Object.entries(
    gridFilters.Config,
  )

  useEffect(() => {
    getData()
  }, [state.Key])

  useEffect(() => {
    setSmartTableColumns(columns)
  }, [columns])

  useEffect(() => {
    if (isFullViewport) handleMutationObserver()
  }, [])

  const getSelectedCount = (state: any | SmartTableState<any>): number => {
    return state.SelectAll
      ? state.Total - state.SelectedIds.length
      : state.SelectedIds
        ? state.SelectedIds.length
        : 0
  }

  const handleTableElementSizing = () => {
    const tableElement: HTMLElement = document.querySelector('.ant-table-body')
    if (tableElement) {
      const scrollHeight =
        window.innerHeight -
        (Math.round(tableElement.getBoundingClientRect().top) + 110)
      setTableScrollHeight(scrollHeight)
    }
  }

  const mutationObserver = new MutationObserver(handleTableElementSizing)

  const handleMutationObserver = () => {
    const config = { attributes: true, childList: true, subtree: true }
    const mainPageContentElement: HTMLElement = document.querySelector(
      '.main__page-content',
    )
    if (mainPageContentElement && isFullViewport)
      mutationObserver.observe(mainPageContentElement, config)

    return () => mutationObserver.disconnect()
  }

  const updateSorting = (column: string, direction: DirectionType) => {
    const copy = { ...state }
    const { Filters } = copy
    copy.Key = ++copy.Key
    Filters.SortColumn = column
    Filters.SortDirection = direction
    Filters.Refetch = false

    setState(copy)
  }

  const updatePaging = (CurrentPage: number, PageSize: number) => {
    const copy = { ...state }
    const { Filters } = copy

    copy.Key = ++copy.Key
    Filters.CurrentPage = CurrentPage
    Filters.PageSize = PageSize
    Filters.Refetch = false
    setState(copy)
  }

  const filterTable = (property: string, value: string) => {
    const copy = { ...state }
    const { Filters } = copy

    copy.Key = ++copy.Key
    copy.SelectedCount = getSelectedCount(copy)
    Filters.CurrentPage = 1
    Filters.Config[property] = value
    setState(copy)
  }

  const removeFilter = (property: string) => {
    const copy = { ...state }
    copy.Key = ++copy.Key
    delete copy.Filters.Config[property]
    copy.SelectedCount = getSelectedCount(copy)
    setState(copy)
  }

  const removeTag = (entry: string) => {
    const copy = { ...search }
    copy[entry] = ''
    setSearch(copy)

    if (setDateRange) setDateRange({ dates: [null, null], numberOfDays: 0 })

    if (!loading) removeFilter(entry)
  }

  const onSelectChange = (keys: any) => {
    const selectAll = store.getItem('grid-filter-select-all')
    const copy = { ...state }
    copy.SelectAll = selectAll === 'true'
    copy.SelectedGridKeys = keys

    if (selectAll) {
      const unselected = copy.Data.filter((d: any) => !keys.includes(d[rowKey]))
      copy.SelectedIds = []
      if (unselected.length) {
        const unselectedIds = unselected.map((u: any) => {
          return u[rowKey]
        })
        const unique = Array.from(new Set(unselectedIds))
        copy.SelectedIds.push(...unique)
      }
    } else copy.SelectedIds = keys

    copy.SelectedCount = getSelectedCount(copy)
    setState(copy)
  }

  const rowSelection = selections && {
    selectedRowKeys: state.SelectedGridKeys,
    onChange: onSelectChange,
    columnWidth: 50,
    onSelectAll: (selected: boolean) => {
      const copy = { ...state }
      copy.SelectAll = selected
      setState(copy)
      if (selected) store.setItem('grid-filter-select-all', selected.toString())
      else store.removeItem('grid-filter-select-all')
    },
    selections: showBatchActionControls
      ? null
      : selections.map((selection: { key: string; onSelect: () => void }) => {
          return {
            key: selection.key,
            text: spacesToProperty(selection.key),
            onSelect: async () => await selection.onSelect(),
          }
        }),
  }

  const setEligibilityRowStatusClass = (status: StatusType): string => {
    let statusCssClass: StatusCSSClassType = ''

    switch (status) {
      case 'Patient Response':
        statusCssClass = 'blue-indicator'
        break
      case 'Pending Patient Response':
      case 'Not Eligible':
      case 'Pending Response':
        statusCssClass = 'yellow-indicator'
        break
      case 'Rejected':
      case 'Failed':
      case 'Failed Validation':
        statusCssClass = 'red-indicator'
        break
      default:
    }
    return statusCssClass
  }

  const setRowClass = (row: ObjAnyPropsType, index: number): string => {
    const errorRow = ''
    let rowStatus = ''
    const rowIgnored = row.Ignored || row.ignored ? 'row-ignore' : ''
    const grayBackground = index % 2 !== 0 ? 'row-gray' : ''

    switch (window.location.pathname) {
      case '/Eligibility':
      case '/Claims':
        rowStatus = setEligibilityRowStatusClass(row.Status)
    }

    return `${errorRow} ${grayBackground} ${rowIgnored} ${rowStatus}`
  }

  const filterColumns = smartTableColumns.map(
    (column: ColumnType, index: number) => ({
      ...column,
      ...SortHeader(
        state.Filters,
        searchInput,
        search,
        setSearch,
        openDate,
        setOpenDate,
        dateRange,
        setDateRange,
        filterTable,
        removeTag,
        updateSorting,
        column.dataIndex?.toString(),
        column.title,
        column.noSort,
        column.type,
        column.options,
        column.filter,
        column.noFilter,
        column.rangeType,
        filterLoading,
      ),
      onHeaderCell: (column: ColumnType) => ({
        width: column.width,
        onResize: handleResize(index, column.dataIndex as string),
      }),
    }),
  )

  const handleResize =
    (index: number, dataIndex: string) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const resizedColumns = [...smartTableColumns]

      const resizedColumn = resizedColumns[index]
      if (
        size.width < resizedColumn.minWidth ||
        size.width > resizedColumn.maxWidth
      )
        return

      // Save the custom column width for later use
      customColumnWidths[dataIndex] = size.width
      localStorage.setItem(
        customColumnStorage,
        JSON.stringify(customColumnWidths),
      )

      resizedColumns[index] = {
        ...resizedColumns[index],
        width: size.width,
      }

      setSmartTableColumns(resizedColumns)
    }

  const datePickerSubComponent: JSX.Element = (
    <Row
      className='filter-tags-container mt-050 mb-050'
      align='middle'
    >
      {datePicker && <Col className='mr-100'>{datePicker}</Col>}
      <Col>
        {properties && (
          <FilterTags
            entries={
              hideFilterTags
                ? entries.filter((entry) => entry[0] !== 'Status')
                : entries
            }
            removeFilter={removeTag}
          />
        )}
      </Col>
    </Row>
  )

  const pagination: PaginationType = {
    showSizeChanger: true,
    current: gridFilters.CurrentPage,
    pageSize: gridFilters.PageSize,
    total: state.Total,
    showTotal: (total: number, range: [number, number]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    onChange: (page: number, pageSize: number) => updatePaging(page, pageSize),
  }

  return (
    <>
      {!noFilter && datePickerSubComponent}
      {toolbar}
      {showBatchActionControls && state.SelectedGridKeys?.length > 0 && (
        <BatchActionGridControls
          selections={selections}
          selectedCount={state.SelectedCount}
          handleClearSelection={onSelectChange}
        />
      )}
      <Table
        className={`${tableClass} smart-table mt-050`}
        rowClassName={setRowClass}
        rowKey={rowKey}
        loading={loading}
        rowSelection={rowSelection}
        columns={filterColumns as ColumnsType<ObjAnyPropsType>}
        dataSource={state.Data}
        scroll={{ y: tableScrollHeight }}
        pagination={pagination}
        onRow={(row, index) => ({
          onClick: (e: any) =>
            setDetail && !e.target.href && setDetail(row, index),
        })}
        components={{
          header: {
            cell: allowColumnResize && ResizableColumnHeader,
          },
        }}
      />
    </>
  )
}
