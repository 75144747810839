import {
  ImageSystem,
  InstallerResponse,
  PearlCredentials,
  ProvisionRequest,
  ProvisionResponse,
} from './pearl-provision-client'
import {
  GetImagingSystems,
  GetPearlInstaller,
  GetPearlStatus,
  ProvisionPearl,
  SavePearlCredentials,
} from './pearlProvisionApi'


export const GetAvailableImagingSystems = async (): Promise<ImageSystem[]> => {
  try {
    const response = await GetImagingSystems()
    return response?.data || []
  } catch {
    return []
  }
}

export const GetPearlInstallerInfo = async (
  customerId: number,
): Promise<InstallerResponse | null> => {
  try {
    const response = await GetPearlInstaller(customerId)
    if (response?.data) {
      return {
        isReady: response.data.isReady ?? false,
        installerUrl: response.data.installerUrl,
      }
    }
    return null
  } catch {
    return null
  }
}

export const SaveCredentials = async (
  customerId: number,
  credentials: PearlCredentials,
): Promise<boolean> => {
  try {
    const response = await SavePearlCredentials(customerId, credentials)
    return response?.data || false
  } catch {
    return false
  }
}

export const ProvisionPearlSystem = async (
  customerId: number,
  request: ProvisionRequest,
): Promise<ProvisionResponse | null> => {
  try {
    const response = await ProvisionPearl(customerId, request)
    if (response?.data) {
      return {
        facilityId: response.data.facilityId ?? null,
        organizationId: response.data.organizationId ?? null,
        existingCustomer: response.data.existingCustomer ?? false,
        message: response.data.message ?? null,
      }
    }
    return null
  } catch {
    return null
  }
}

export const GetPearlConfigurationStatus = async (customerId: number) => {
  return await GetPearlStatus(customerId)
}

export const hasImageSyncFeature = async (
  customerId: number,
): Promise<boolean> => {
  try {
    const response = await GetPearlStatus(customerId)
    return response?.data?.hasImageSync ?? false
  } catch {
    return false
  }
}