/* tslint:disable */
/* eslint-disable */
/**
 * Pearl
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ImageSystem
 */
export interface ImageSystem {
    /**
     * 
     * @type {string}
     * @memberof ImageSystem
     */
    'imageSystemIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageSystem
     */
    'imageSystemName'?: string | null;
}
/**
 * 
 * @export
 * @interface InstallerResponse
 */
export interface InstallerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InstallerResponse
     */
    'isReady'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InstallerResponse
     */
    'installerUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface PearlConfiguration
 */
export interface PearlConfiguration {
    /**
     * 
     * @type {number}
     * @memberof PearlConfiguration
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PearlConfiguration
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PearlConfiguration
     */
    'officeId'?: string | null;
}
/**
 * 
 * @export
 * @interface PearlConfigurationStatus
 */
export interface PearlConfigurationStatus {
    /**
     * 
     * @type {boolean}
     * @memberof PearlConfigurationStatus
     */
    'hasImageSync'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PearlConfigurationStatus
     */
    'hasCredentials'?: boolean;
}
/**
 * 
 * @export
 * @interface PearlCredentials
 */
export interface PearlCredentials {
    /**
     * 
     * @type {string}
     * @memberof PearlCredentials
     */
    'organizationId': string;
    /**
     * 
     * @type {string}
     * @memberof PearlCredentials
     */
    'officeId': string;
    /**
     * 
     * @type {string}
     * @memberof PearlCredentials
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof PearlCredentials
     */
    'clientSecret': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PearlImageOrientationTypes = {
    None: 'None',
    PatientLeft: 'PatientLeft',
    PatientRight: 'PatientRight'
} as const;

export type PearlImageOrientationTypes = typeof PearlImageOrientationTypes[keyof typeof PearlImageOrientationTypes];


/**
 * 
 * @export
 * @enum {string}
 */

export const PearlImageTypes = {
    Other: 'Other',
    Bitewing: 'Bitewing',
    Pano: 'Pano',
    Periapical: 'Periapical'
} as const;

export type PearlImageTypes = typeof PearlImageTypes[keyof typeof PearlImageTypes];


/**
 * 
 * @export
 * @interface PearlPatientImage
 */
export interface PearlPatientImage {
    /**
     * 
     * @type {number}
     * @memberof PearlPatientImage
     */
    'imageId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PearlPatientImage
     */
    'imageFilename'?: string | null;
    /**
     * 
     * @type {PearlImageTypes}
     * @memberof PearlPatientImage
     */
    'imageType'?: PearlImageTypes;
    /**
     * 
     * @type {PearlImageOrientationTypes}
     * @memberof PearlPatientImage
     */
    'imageOrientation'?: PearlImageOrientationTypes;
    /**
     * 
     * @type {string}
     * @memberof PearlPatientImage
     */
    'imageDateTaken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PearlPatientImage
     */
    'imageToothRangeDisplay'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PearlPatientImage
     */
    'imageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PearlPatientImage
     */
    'imageUrlRaw'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PearlPatientImage
     */
    'imageUrlThumbnail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PearlPatientImage
     */
    'imageUrlThumbnailNoPadding'?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProvisionRequest
 */
export interface ProvisionRequest {
    /**
     * 
     * @type {string}
     * @memberof ProvisionRequest
     */
    'imageSystemIdentifier': string;
}
/**
 * 
 * @export
 * @interface ProvisionResponse
 */
export interface ProvisionResponse {
    /**
     * 
     * @type {string}
     * @memberof ProvisionResponse
     */
    'facilityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProvisionResponse
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProvisionResponse
     */
    'existingCustomer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProvisionResponse
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface SearchPearlPatientImages
 */
export interface SearchPearlPatientImages {
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatientImages
     */
    'organizationId': string;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatientImages
     */
    'officeId': string;
}
/**
 * 
 * @export
 * @interface SearchPearlPatientImagesResponse
 */
export interface SearchPearlPatientImagesResponse {
    /**
     * 
     * @type {number}
     * @memberof SearchPearlPatientImagesResponse
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatientImagesResponse
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatientImagesResponse
     */
    'officeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatientImagesResponse
     */
    'pearlPatientId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatientImagesResponse
     */
    'searchStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatientImagesResponse
     */
    'searchEndDate'?: string | null;
    /**
     * 
     * @type {Array<PearlPatientImage>}
     * @memberof SearchPearlPatientImagesResponse
     */
    'patientImages'?: Array<PearlPatientImage> | null;
}
/**
 * 
 * @export
 * @interface SearchPearlPatients
 */
export interface SearchPearlPatients {
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatients
     */
    'organizationId': string;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatients
     */
    'officeId': string;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatients
     */
    'patientFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatients
     */
    'patientLastName': string;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatients
     */
    'patientDateOfBirth'?: string | null;
}
/**
 * 
 * @export
 * @interface SearchPearlPatientsResponse
 */
export interface SearchPearlPatientsResponse {
    /**
     * 
     * @type {number}
     * @memberof SearchPearlPatientsResponse
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatientsResponse
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatientsResponse
     */
    'officeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatientsResponse
     */
    'pearlPatientId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatientsResponse
     */
    'patientFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatientsResponse
     */
    'patientLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatientsResponse
     */
    'patientDateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatientsResponse
     */
    'patientLastVisitDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPearlPatientsResponse
     */
    'patientLastImageDate'?: string | null;
}

/**
 * PearlApi - axios parameter creator
 * @export
 */
export const PearlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pearlGetPearlCustomerSettings: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('pearlGetPearlCustomerSettings', 'customerId', customerId)
            const localVarPath = `/customer/{customerId}/settings`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pearlIsPearlConfigured: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('pearlIsPearlConfigured', 'customerId', customerId)
            const localVarPath = `/customer/{customerId}/is-configured`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {SearchPearlPatients} searchPearlPatients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pearlSearchPearlCustomerPatients: async (customerId: number, searchPearlPatients: SearchPearlPatients, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('pearlSearchPearlCustomerPatients', 'customerId', customerId)
            // verify required parameter 'searchPearlPatients' is not null or undefined
            assertParamExists('pearlSearchPearlCustomerPatients', 'searchPearlPatients', searchPearlPatients)
            const localVarPath = `/customer/{customerId}/patients`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPearlPatients, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} pearlPatientId 
         * @param {SearchPearlPatientImages} searchPearlPatientImages 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pearlSearchPearlPatientImages: async (customerId: number, pearlPatientId: string, searchPearlPatientImages: SearchPearlPatientImages, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('pearlSearchPearlPatientImages', 'customerId', customerId)
            // verify required parameter 'pearlPatientId' is not null or undefined
            assertParamExists('pearlSearchPearlPatientImages', 'pearlPatientId', pearlPatientId)
            // verify required parameter 'searchPearlPatientImages' is not null or undefined
            assertParamExists('pearlSearchPearlPatientImages', 'searchPearlPatientImages', searchPearlPatientImages)
            const localVarPath = `/customer/{customerId}/patients/{pearlPatientId}/images`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"pearlPatientId"}}`, encodeURIComponent(String(pearlPatientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPearlPatientImages, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PearlApi - functional programming interface
 * @export
 */
export const PearlApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PearlApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pearlGetPearlCustomerSettings(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PearlConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pearlGetPearlCustomerSettings(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pearlIsPearlConfigured(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pearlIsPearlConfigured(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {SearchPearlPatients} searchPearlPatients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pearlSearchPearlCustomerPatients(customerId: number, searchPearlPatients: SearchPearlPatients, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchPearlPatientsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pearlSearchPearlCustomerPatients(customerId, searchPearlPatients, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} pearlPatientId 
         * @param {SearchPearlPatientImages} searchPearlPatientImages 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pearlSearchPearlPatientImages(customerId: number, pearlPatientId: string, searchPearlPatientImages: SearchPearlPatientImages, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchPearlPatientImagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pearlSearchPearlPatientImages(customerId, pearlPatientId, searchPearlPatientImages, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PearlApi - factory interface
 * @export
 */
export const PearlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PearlApiFp(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pearlGetPearlCustomerSettings(customerId: number, options?: any): AxiosPromise<PearlConfiguration> {
            return localVarFp.pearlGetPearlCustomerSettings(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pearlIsPearlConfigured(customerId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.pearlIsPearlConfigured(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {SearchPearlPatients} searchPearlPatients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pearlSearchPearlCustomerPatients(customerId: number, searchPearlPatients: SearchPearlPatients, options?: any): AxiosPromise<Array<SearchPearlPatientsResponse>> {
            return localVarFp.pearlSearchPearlCustomerPatients(customerId, searchPearlPatients, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} pearlPatientId 
         * @param {SearchPearlPatientImages} searchPearlPatientImages 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pearlSearchPearlPatientImages(customerId: number, pearlPatientId: string, searchPearlPatientImages: SearchPearlPatientImages, options?: any): AxiosPromise<SearchPearlPatientImagesResponse> {
            return localVarFp.pearlSearchPearlPatientImages(customerId, pearlPatientId, searchPearlPatientImages, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PearlApi - object-oriented interface
 * @export
 * @class PearlApi
 * @extends {BaseAPI}
 */
export class PearlApi extends BaseAPI {
    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PearlApi
     */
    public pearlGetPearlCustomerSettings(customerId: number, options?: AxiosRequestConfig) {
        return PearlApiFp(this.configuration).pearlGetPearlCustomerSettings(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PearlApi
     */
    public pearlIsPearlConfigured(customerId: number, options?: AxiosRequestConfig) {
        return PearlApiFp(this.configuration).pearlIsPearlConfigured(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {SearchPearlPatients} searchPearlPatients 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PearlApi
     */
    public pearlSearchPearlCustomerPatients(customerId: number, searchPearlPatients: SearchPearlPatients, options?: AxiosRequestConfig) {
        return PearlApiFp(this.configuration).pearlSearchPearlCustomerPatients(customerId, searchPearlPatients, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {string} pearlPatientId 
     * @param {SearchPearlPatientImages} searchPearlPatientImages 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PearlApi
     */
    public pearlSearchPearlPatientImages(customerId: number, pearlPatientId: string, searchPearlPatientImages: SearchPearlPatientImages, options?: AxiosRequestConfig) {
        return PearlApiFp(this.configuration).pearlSearchPearlPatientImages(customerId, pearlPatientId, searchPearlPatientImages, options).then((request) => request(this.axios, this.basePath));
    }
}


